<template>
  <modal-block modal-id="ModalRoomPrice" close-button="yes">
    <template v-slot:content>
      <div class="contentBox">
        <page-header :iconsize="this.$screen.height>600?40:30" o icon="ruble-circle-underline">
          <template v-slot:title>ЦЕНЫ НА РАЗМЕЩЕНИЕ</template>
          <template v-slot:text>
            <p style="padding-top: 10px; font-weight: bold; font-size: 16px">САНАТОРНО-КУРОРТНАЯ ПУТЕВКА</p>
            <p style="padding-top: 10px"><b>В СТОИМОСТЬ ВХОДИТ:</b> ПРОЖИВАНИЕ, ЛЕЧЕНИЕ, ТРЕХРАЗОВОЕ ПИТАНИЕ, СПА, БАССЕЙН И РАЗВЛЕКАТЕЛЬНАЯ ПРОГРАММА</p>

            <div class="roomName" style="padding-top: 6px">
              <span  style="font-size: 20px; font-weight: lighter;">НОМЕР  </span><span style="font-size: 20px; font-weight: bold; text-transform: uppercase">«{{$store.state.rooms[$store.state.bookingRoom].name}}»</span>
            </div>


            <div v-if="$store.state.bookingRoom[0]==='familyLux'" class="familyNote" style="padding-top: 6px">
              <span style="font-size: 15px; color: orange; font-weight: bold; text-transform: uppercase">Минимальное количество гостей в этом номере 4 - человека</span>
            </div>

          </template>
        </page-header>
        <div class="content" :class="$screen.device">
          <div id="RoomPrice" :class="$screen.device">

            <div class="dateNav noSelect">
              <div @click="nav('minus')" class="btn"><img :src="require('@/assets/icons/left-arrow.png')"></div>
              <div  class="label"><span v-if="showPrice">{{price[activePrice].label}}</span></div>
              <div @click="nav('plus')" class="btn"><img :src="require('@/assets/icons/right-arrow.png')"></div>
            </div>
            <div  class="table">
              <div class="item">
                <div class="label"><img class="icon" :src="require('@/assets/icons/adult.png')"><span class="name" :style="{fontSize: labelFontSize+'px'}">ВЗРОСЛЫЙ</span><span class="desc"  :style="{fontSize: placeFontSize+'px'}">(ОСНОВНОЕ МЕСТО)</span></div>
                <div v-if="showPrice" class="price"><span>{{price[activePrice].rooms[this.$store.state.bookingRoom].adultMain}} руб.</span></div>
              </div>
              <div v-if="$store.state.bookingRoom[0]!=='familyLux'" class="item">
                <div class="label"><img class="icon" :src="require('@/assets/icons/adult.png')"><span class="name" :style="{fontSize: labelFontSize+'px'}">ВЗРОСЛЫЙ</span><span class="desc" :style="{fontSize: placeFontSize+'px'}">(ДОП МЕСТО)</span></div>
                <div v-if="showPrice" class="price"><span>{{price[activePrice].rooms[this.$store.state.bookingRoom].adultDop}} руб.</span></div>
              </div>
              <div class="item">
                <div class="label"><img class="icon" :src="require('@/assets/icons/kids.png')"><span class="name" :style="{fontSize: labelFontSize+'px'}">РЕБЕНОК (4-14 лет)</span><span class="desc" :style="{fontSize: placeFontSize+'px'}">(ОСНОВНОЕ МЕСТО)</span></div>
                <div v-if="showPrice" class="price"><span>{{price[activePrice].rooms[this.$store.state.bookingRoom].childMain}} руб.</span></div>
              </div>
              <div v-if="$store.state.bookingRoom[0]!=='familyLux'" class="item">
                <div class="label"><img class="icon" :src="require('@/assets/icons/kids.png')"><span class="name" :style="{fontSize: labelFontSize+'px'}">РЕБЕНОК (4-14 лет)</span><span class="desc" :style="{fontSize: placeFontSize+'px'}">(ДОП МЕСТО)</span></div>
                <div v-if="showPrice" class="price"><span>{{price[activePrice].rooms[this.$store.state.bookingRoom].childDop}} руб.</span></div>
              </div>
              <div v-if="$store.state.bookingRoom[0]!=='familyLux'" class="item">
                <div class="label"><img class="icon" :src="require('@/assets/icons/door.png')"><span class="name" :style="{fontSize: labelFontSize+'px'}">ОДНОМЕСТНОЕ</span><span class="desc" :style="{fontSize: placeFontSize+'px'}">(ОДИН ЧЕЛОВЕК В НОМЕРЕ)</span></div>
                <div v-if="showPrice" class="price"><span>{{price[activePrice].rooms[this.$store.state.bookingRoom].single}} руб.</span></div>
              </div>

            </div>
            <v-btn @click="this.$commit('bookFromPrice')"  :style="{fontSize:$screen.width<375?'9px':'12px'} " :block="true" variant="contained-flat" :border="false" height="40"  size="small" color="green">
              <p style="color: white">ЗАБРОНИРОВАТЬ ЭТОТ НОМЕР</p>
            </v-btn>

          </div>

<!--          <div  class="Promo">-->
<!--              <div class="header">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="m8.85 17.825l3.15-1.9l3.15 1.925l-.825-3.6l2.775-2.4l-3.65-.325l-1.45-3.4l-1.45 3.375l-3.65.325l2.775 2.425l-.825 3.575ZM5.825 22l1.625-7.025L2 10.25l7.2-.625L12 3l2.8 6.625l7.2.625l-5.45 4.725L18.175 22L12 18.275L5.825 22ZM12 13.25Z"/></svg>-->
<!--                <span>АКЦИЯ</span>-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="m8.85 17.825l3.15-1.9l3.15 1.925l-.825-3.6l2.775-2.4l-3.65-.325l-1.45-3.4l-1.45 3.375l-3.65.325l2.775 2.425l-.825 3.575ZM5.825 22l1.625-7.025L2 10.25l7.2-.625L12 3l2.8 6.625l7.2.625l-5.45 4.725L18.175 22L12 18.275L5.825 22ZM12 13.25Z"/></svg>-->
<!--              </div>-->

<!--              <p>с 20 ноября по 25 декабря - при бронировании на сайте - скидка 15%. </p>-->
<!--          </div>-->

          <div class="paymentRules">
            <div class="title">
              <img class="icon" :src="require('@/assets/icons/credit-card.png')">
              <span >ПРАВИЛА ОПЛАТЫ</span>
            </div>
            <p class="rule"><span>* </span>ДЛЯ ОФОРМЛЕНИИЯ БРОНИРОВАНИЯ НЕОБХОДИМО ВНЕСЕНИЕ ПРЕДОПЛАТЫ В РАЗМЕРЕ СТОИМОСТИ ОДНОГО ДНЯ ПРОЖИВАНИЯ ВСЕХ ГОСТЕЙ В НОМЕРЕ</p>
            <div v-if="showPaymentInfo" class="cards">
              <p>МЫ ПРИНИМАЕМ К ОПЛАТЕ ВСЕ КАРТЫ РОССИЙСКИХ БАНКОВ</p>
              <img  class="icon" :src="require('@/assets/img/payments.png')">
            </div>
          </div>

        </div>
      </div>
    </template>

  </modal-block>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import ModalBlock from "@/components/ModalBlock";


export default {
  name: 'ModalRoomPrice',
  components: {ModalBlock, PageHeader,},
  methods:{
    nav(n){

      let total= this.price.length
      let activePrice = this.activePrice+1
      let change = false
      if(n==='plus' && activePrice<total){this.activePrice=this.activePrice+1; change=true}
      if(n==='minus' && activePrice>1){this.activePrice=this.activePrice-1; change=true}

      if(change){
        this.showPrice = false
        setTimeout(()=>{this.showPrice=true},100)
      }



    }
  },
  computed:{
    labelFontSize(){
      let size = 14
      if(this.$screen.width < 400){size=11}
      return size

    },
    placeFontSize(){
      let size = 9
      if(this.$screen.width < 400){size=10}
      return size

    }
  },
  data: () => ({
    activePrice:0,
    showPrice:true,
    price: [],
    showPaymentInfo:false,
  }),
  mounted() {
    this.price = this.$store.state.price
  }
}
</script>


<style>
.Promo{
  background-color: #c77a00;
  color: white;
  text-align: center;
  padding: 10px;
  margin-top: 5px;
}

.Promo>.header{
  grid-auto-flow: column;
  display: grid;
  justify-content: center;
  column-gap: 5px;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.Promo>.header>span{font-size: 24px; font-weight: bold}

  #ModalRoomPrice{max-width: 650px; width: 100%;}
  #ModalRoomPrice.mobile{width: 100%; height: 100%}
  #ModalRoomPrice>.contentBox{overflow-y: auto}
  #ModalRoomPrice>.contentBox>.content{}
  /*#ModalRoomPrice>.contentBox>.content.mobile{padding: 20px}*/

  #RoomPrice{}
  #RoomPrice>.roomName{text-align: center; padding-top: 10px}
  #RoomPrice>.dateNav{display: grid; grid-template-columns: auto 1fr auto; background-color: white; align-items: center; border-bottom: 2px solid #3e3e3e}
  #RoomPrice>.dateNav>.btn{cursor:pointer; transition-duration:100ms; display: grid; background-color: #bd9f7a; height: 40px; width: 60px; align-items: center; justify-items: center}
  #RoomPrice.desktop>.dateNav>.btn:active{background-color: #e5c8a5;}
  #RoomPrice>.dateNav>.btn:hover{ background-color: #d2b28b;}
  #RoomPrice>.dateNav>.btn>img{height: 20px; filter: invert()}
  #RoomPrice>.dateNav>.label{text-align: center;}

  #RoomPrice>.table{}
  #RoomPrice>.table>.item{
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: white;
    border-bottom: 1px solid #a19e9e;
  }

  #RoomPrice>.table>.item>.label{padding: 4px 10px; background-color: #fdfcfc; display: grid; align-items: center; grid-auto-flow: column; justify-content: flex-start; column-gap: 4px}
  #RoomPrice>.table>.item>.label>.icon{height: 14px;}
  #RoomPrice>.table>.item>.label>.name{}
  #RoomPrice>.table>.item>.label>.desc{color: #7b7878;}

  #RoomPrice>.table>.item>.price{padding: 0px 10px; display: grid; align-items: center; background-color: #f1e7e7;}
  .paymentRules{padding: 0px 20px; padding-bottom: 20px; display: grid; row-gap: 8px;}
  .paymentRules>.rule{font-size: 12px; }
  .paymentRules>.title{padding-top: 10px; column-gap: 6px; height:40px; display: grid; grid-auto-flow: column; align-content: center; justify-content: center; align-items: center; justify-items: center }
  .paymentRules>.title>span{text-align: center; font-weight: lighter; font-size: 22px; line-height: 22px}
  .paymentRules>.title>.icon{height: 30px;}
  .paymentRules>.cards{display: grid; justify-content: center; justify-items: center; padding-top: 20px}
  .paymentRules>.cards>p{font-size: 13px; line-height: 13px; text-align: center}
  .paymentRules>.cards>img{height: 50px;}

</style>